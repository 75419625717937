import _objectSpread from "/Users/itnew000/Desktop/ofht20230915/htcode/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import Common from '../../assets/js/common';
export default {
  name: "ResourceFolder",
  props: ["data", "initPath"],
  data: function data() {
    return {
      editTitleValue: null,
      color: "#06a7ff",
      editId: -1,
      //重命名
      detailId: -1,
      //详细信息的id
      currentValue: "",
      //当前选中的
      checkArr: [],
      checkNumber: -1,
      isShowMenu: false,
      //是否显示右键弹出框
      topNumber: 0,
      //右键弹出框的定位
      leftNumber: 0,
      //右键弹出框的定位
      clickTimes: 0,
      //记录点击次数
      Common: Common,
      userInfo: this.Ls.getParseToken('nof_userInfo'),
      localData: []
    };
  },
  computed: {
    suffixFun: function suffixFun() {
      // 后缀
      return function (secondName) {
        var arr = secondName.split("/");
        var len = arr.length - 1;
        return arr[len];
      };
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    //点击其他地方右键弹框消失
    document.addEventListener("click", function () {
      _this2.isShowMenu = false;
      _this2.checkNumber = -1;
    });
    //初始化路径
    this.initPathFn();
  },
  created: function created() {
    //获取网盘格式的数据
    //   this.getSubItem();
    //上一页传递过来的数据
    this.localData = this.data.dataList;
  },
  methods: {
    //点击循环出来的文件夹或者资源
    clickItem: function clickItem(value) {
      if (value.type == 1) {
        //资源
        this.viewDeatail(value.url, value.content);
      } else {
        //文件夹
        this.danji(value);
      }
    },
    //取消重命名
    cancelEdit: function cancelEdit() {
      this.editTitleValue = null;
      this.editId = -1;
    },
    // 确定重命名
    confirmEdit: function confirmEdit() {
      this.renameCourseStructure(this.currentValue);
      /*this.service.axiosUtils({
        requestName: 'deleteCourseStructure',
        data: {
          id:row.id
        },
        sucFn: res => {
          if(res.status == 1){
            //确认是否删除的弹框消失隐藏
            this.$parent.centerDialogVisible=false;
            this.$toast.success(res.message)
            this.$parent.reloadList()
          }else{
            this.$toast.error(res.message)
          }
        }
      })*/
    },
    //添加修改
    renameCourseStructure: function renameCourseStructure(data) {
      var _this3 = this;
      var postData = {
        name: this.editTitleValue,
        id: data.id,
        userId: this.userInfo.userId,
        roleId: this.userInfo.roleId
      };
      console.log("postData", postData);

      /* if (valid) {*/
      var requestName = "";
      // if (this.isEdit) {
      requestName = "renameCourseStructure";
      /* } else {
         //添加
         requestName = "addCourseStructure"
       }*/
      this.service.axiosUtils({
        requestName: requestName,
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this3.$toast.success(res.message);
            /* this.file="";
             this.drawer = false;
              this.$refs.commonDrawer.drawer = false;
             formRef.resetFields();*/
            _this3.cancelEdit();
            //重新拉取列表
            _this3.$parent.reloadList();
          } else {
            _this3.$toast.error(res.message);
          }
        }
      });
      // }
    },
    //移入
    moveFn: function moveFn(event, v) {
      this.detailId = v.id;
      //判断距离
      // this.getEleBottomDis(event)
    },
    //判断距离
    getEleBottomDis: function getEleBottomDis(event) {
      /*可视区窗口高度*/
      var scrollHeight = Math.floor(document.querySelector(".right_main_inner").scrollTop);
      /*文档滚动高度*/
      var clientHeight = document.documentElement.clientHeight;
      /*当前元素与页面顶部距离*/
      var topDis = event.target.offsetTop + 370;

      /*当前元素高度*/
      var offsetHeightDis = event.target.offsetHeight;
      var bottomDis = scrollHeight + clientHeight - topDis - offsetHeightDis;
      this.$nextTick(function () {
        var hoverEleHeight = document.querySelector(".detailed").offsetHeight;
        console.log(bottomDis, hoverEleHeight);
        // if(hoverEleHeight < 382) hoverEleHeight=382;
        if (bottomDis < hoverEleHeight) {
          // event.target..style.top = '-55px'
          document.querySelector(".detailed").style.top = '-' + hoverEleHeight + 'px';
        } else {
          // document.querySelector('.hoverList' + index).style.top = '61px'
          document.querySelector(".detailed").style.top = '152px';
        }
      });
    },
    //移出
    outFn: function outFn() {
      this.detailId = -1;
    },
    //打开
    menuClick: function menuClick(t) {
      var _this4 = this;
      //1打开 2禁用启用 3修改/重传 4详细信息 5图文编辑 6删除 7重命名文件夹（层级）
      switch (t) {
        case 1:
          if (this.currentValue.type == 1) {
            //资源
            this.viewDeatail(this.currentValue.url, this.currentValue.content);
          } else {
            //文件夹
            this.danji(this.currentValue);
          }
          break;
        case 2:
          /*   //禁用启用
             this.$parent.centerDialogVisible=true;
             //禁用
             if(this.currentValue.state == 1){
               this.$parent.dialog.title="禁用";
               this.$parent.dialog.content="确定禁用 "+this.currentValue.name+" 吗？";
             }else{
               this.$parent.dialog.title="启用";
               this.$parent.dialog.content="确定启用 "+this.currentValue.name+" 吗？";
             }*/

          if (this.currentValue.type == 1) {
            //资源
            //禁用/启用资源
            this.$parent.changeResourceState(this.currentValue);
          } else {
            console.log("文件夹");
            this.changeState(this.currentValue);
          }
          break;
        case 3:
          //上传
          //重新传
          // this.showAddResource(1);
          this.$parent.detail(this.currentValue, t);
          break;
        case 4:
          //详细信息

          break;
        case 5:
          //图文编辑
          /*  this.formFile={...this.currentRow};
            this.formFile.id=this.currentRow.id;
            this.formFile.urlList=[];
            console.log(this.formFile)
            this.showEditor(1)*/

          this.$parent.detail(this.currentValue, 4);
          break;
        case 6:
          //删除
          /* if(this.currentValue.type == 1){*/
          //资源
          this.$parent.detail(this.currentValue, 1);
          /*}else{
            //文件夹（层级）
            this.deleteFn(this.currentValue)
          }*/

          break;
        case 7:
          //重命名
          // this.isEdit=true;
          this.editId = this.currentValue.id;
          this.editTitleValue = this.currentValue.name;
          var _this = this;
          setTimeout(function () {
            // console.log("有没有啊",this.$refs.refInput239)
            // console.log("refInput",this.$refs.refInput239[0].focus)
            _this4.$refs["refInput" + _this4.currentValue.id][0].focus();
          }, 300);
          break;
      }
    },
    //删除结构
    deleteFn: function deleteFn(row) {
      var _this5 = this;
      this.service.axiosUtils({
        requestName: 'deleteCourseStructure',
        data: {
          id: row.id
        },
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            //确认是否删除的弹框消失隐藏
            _this5.$parent.centerDialogVisible = false;
            _this5.$toast.success(res.message);
            _this5.$parent.reloadList();
          } else {
            _this5.$toast.error(res.message);
          }
        }
      });
    },
    //修改文件夹（层级）状态  禁用启用
    changeState: function changeState(value) {
      var _this6 = this;
      // this.courseData.roleId=this.userInfo.roleId;
      var postData = {
        userId: this.userInfo.userId,
        roleId: this.userInfo.roleId,
        id: value.id
      };
      if (value.state == 1) {
        postData.state = -1;
      } else {
        postData.state = 1;
      }
      this.service.axiosUtils({
        requestName: 'structureChangeState',
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this6.$toast.success(res.message);
            //刷新列表
            _this6.$parent.reloadList();
          } else {
            _this6.$toast.error(res.message);
          }
          // this.initData();
        }
      });
    },
    //显示右键弹框菜单
    viewMenu: function viewMenu(v, k, e) {
      this.currentValue = v;
      var documentScrollTop = document.querySelector(".right_main_inner").scrollTop;
      this.topNumber = e.pageY - 280 - document.querySelector(".table_top_box").offsetHeight - 10 + documentScrollTop;
      var leftO = document.querySelector(".left_main_inner").offsetWidth + 52;
      this.leftNumber = e.pageX - leftO;
      this.isShowMenu = true;
      //赋值当前选中的样式
      this.checkNumber = v.id;
    },
    //  单击文件夹
    danji: function danji(value) {
      this.nextPage(value);
      /* let _this = this
       _this.clickTimes++;
       if (_this.clickTimes === 2) {
         _this.clickTimes = 0;
         //  处理双击事件...
        }
       setTimeout(function () {
         if (_this.clickTimes === 1) {
           _this.clickTimes = 0;
           //  处理单击事件...
           console.log("单击")
           _this.nextPage(value);
          }
       }, 250)*/
    },
    //查看资源详情
    viewDeatail: function viewDeatail(url, content) {
      if (url) {
        window.open(url, "_blank");
      } else if (content) {
        //预览html
        sessionStorage.setItem("html", content);
        var href = this.$router.resolve({
          //使用resolve
          path: '/view/editor'
        });
        window.open(href.href, '_blank');
      }
    },
    //路径初始化
    initPathFn: function initPathFn() {
      this.path = this.$route.query.Path || this.initPath;
      var arrPath = this.path.split("/");
      var strPath = "";
      this.navData = [];
      for (var i = 0; i < arrPath.length; i++) {
        var arrO = arrPath[i].split(">");
        if (i == 0) {
          strPath = arrO[0] + ">" + arrO[1];
        } else {
          strPath = strPath + "/" + arrO[0] + ">" + arrO[1];
        }
        var jsonPath = {
          text: arrO[0],
          path: "/course/edit/courseResource",
          name: "EditCourseResource",
          params: {
            id: arrO[1]
          },
          query: {
            PageIndex: 1,
            Path: strPath
          }
        };
        this.navData.push(jsonPath);
      }
    },
    //双击跳转层级
    nextPage: function nextPage(data) {
      console.log("点击文件夹或者层级", this.path);
      // return;
      this.currentTree = data;
      //删除树状菜单的子级，否则会因为数据太多挂到地址栏超出地址栏的最大字符导致数据不全的问题
      // delete this.currentTree.sublevel
      this.currentTree.courseId = this.$route.query.courseId;
      // this.currentTree.pageSize=10;
      // this.currentTree.pageIndex=1;
      this.currentTree.structureId = this.currentTree.id;
      var queryData = _objectSpread({}, this.$route.query);
      queryData.tree = JSON.stringify(this.currentTree);
      //设置路径
      var name = data.name;
      var id = data.id;
      queryData.Path = this.path + "/" + name + ">" + id;
      this.$parent.setCurrentTree(this.currentTree);
      this.$router.replace({
        path: this.$route.path,
        query: queryData
      });
    },
    getSubItem: function getSubItem() {
      var _this7 = this;
      var post = {
        userId: this.userInfo.userId,
        roleId: this.userInfo.roleId,
        courseId: this.$route.query.courseId,
        selectedId: 238
      };
      this.service.axiosUtils({
        requestName: "coursestructureGetSubItem",
        data: post,
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this7.localData = res.data;
          }
        }
      });
    }
  }
};