import _typeof from "/Users/itnew000/Desktop/ofht20230915/htcode/node_modules/@babel/runtime/helpers/esm/typeof.js";
export default {
  name: 'Hint',
  props: ['data'],
  data: function data() {
    return {
      isView: 2
    };
  },
  created: function created() {
    if (_typeof(this.data.list[0]) == 'object') {
      this.isView = 1;
    }
  }
};